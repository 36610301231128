import React from 'react';
import { LandingPageWrapper } from 'components/global/LandingPageWrapper';
import { Footer } from 'components/global/Footer';
import { Head } from './Homepage/components/Head';
import { SEO, SEO_CORE_CONTENT } from 'components/global/SEO';
import { Nav } from 'components/global/Nav';

import {
  BeforeAfterPop,
  ACNE_BEFORE_AFTER_CONTENT,
} from 'components/modules/pop-redesign/BeforeAfterPop';
import {
  SplitHeroPop,
  ACNE_SPLIT_HERO_CONTENT,
} from 'components/modules/pop-redesign/SplitHeroPop';
import {
  ACNE_INGREDIENTS_CONTENT,
  IngredientsPop,
} from 'components/modules/pop-redesign/IngredientsPop';
import {
  StepsPop,
  ACNE_STEPS_CONTENT,
} from 'components/modules/pop-redesign/StepsPop';
import {
  ACNE_CUSTOM_FORMULA_CONTENT,
  CustomFormulaPop,
} from 'components/modules/pop-redesign/CustomFormulaPop';
import {
  ACNE_FOOTER_DISCLAIMER_CONTENT,
  FooterDisclaimerPop,
} from 'components/modules/pop-redesign/FooterDisclaimerPop';
import { ConvertSnippet } from 'components/global/ConvertSnippet';
import {
  POP_REDESIGN_BANNER_CONTENT,
  POP_REDESIGN_SEPTEMBER_PROMO_BANNER_CONTENT,
} from 'components/global/Nav/components';
import { useFlag } from 'utils/featureFlagsAndMetrics';

export const Acne = () => {
  return (
    <LandingPageWrapper>
      <Head />
      <ConvertSnippet />
      <SEO {...SEO_CORE_CONTENT} />
      <Nav
        bannerProps={
          useFlag('acne-banner-copy')
            ? [POP_REDESIGN_SEPTEMBER_PROMO_BANNER_CONTENT]
            : [POP_REDESIGN_BANNER_CONTENT]
        }
      />
      <SplitHeroPop {...ACNE_SPLIT_HERO_CONTENT} />
      <StepsPop {...ACNE_STEPS_CONTENT} />
      <BeforeAfterPop {...ACNE_BEFORE_AFTER_CONTENT} />
      <IngredientsPop {...ACNE_INGREDIENTS_CONTENT} />
      <CustomFormulaPop {...ACNE_CUSTOM_FORMULA_CONTENT} />
      <Footer
        disclaimer={<FooterDisclaimerPop {...ACNE_FOOTER_DISCLAIMER_CONTENT} />}
      />
    </LandingPageWrapper>
  );
};
